









import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  Component,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'chatroom',
})
export default class Chatroom extends Vue {
  private list: any = []
  private defChatAvatar: string = 'https://oss.guojiangmedia.com/planr/chat-room-icon2.png'
  private type = +this.$route.query.type || 0

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  private mounted() {
    this.getList()
  }

  private async getList() {
    try {
      const res: any = await api.getChatRoom({
        wechat_id: this.wxId
      })
      this.list = res.list
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 点击群
   */
  private chooseChatroom(row: any) {
    if (this.type) {
      this.handleInviteGroup(row)
      return
    }
    WechatModule.changeFriendId(row.id)
    WechatModule.changeFriendStrId(row.username)
    WechatModule.changeFriendInfo(JSON.stringify(row))
    WechatModule.changeIsChatRoom(true)
    this.$router.push({
      name: 'Chat'
    })
  }

  /**
   * @func 发送群邀请
   * @param 群信息
   */
  private async handleInviteGroup(row: any) {
    try {
      await api.doChatroomTask({
        wechat_id: this.wxId,
        task_type: 2,
        invite_user: {
          chatroom_id: row.username,
          wxid: this.friendStrId
        }
      })
      this.$router.go(-1)
    } catch (e) {
      console.log(e)
    }
  }
}
